class CustomerPersonsActionsComponent extends Component {

    static name() {
        return "CustomerPersonsActionsComponent";
    }

    static componentName() {
        return "CustomerPersonsActionsComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    getComputed() {
        return  {
            session: function () {
                return this.$store.getters.getSession;
            },
            currentCustomerPerson: function () {
                return this.$store.getters.getCurrentCustomerPerson;
            },
        };
    }

    getMethods() {
        return {
            EditPerson: this.EditPerson,
            DisablePerson: this.DisablePerson,
            ActivatePerson: this.ActivatePerson,
        };
    }


    EditPerson() {
        console.log(this.data.Code, 'Code del Row to Edit')
        this.$store.dispatch('setCurrentCustomerPerson', this.data.Code);
        //Esto es para q lo select se carguen del state en el modal de editar
        //let currentCustomerPerson = this.$store.getters.getCurrentCustomerPerson
        /*this.$store.dispatch('updateSelectLinkto', {table: 'Department', value: currentCustomerPerson.Department});
        this.$store.dispatch('updateSelectLinkto', {
            table: 'JobPosition',
            value: currentCustomerPerson.JobPosition
        });
        this.$store.dispatch('updateSelectLinkto', {
            table: 'WebAccessGroup',
            value: currentCustomerPerson.WebAccessGroup
        });
        this.$store.dispatch('updateSelectLinkto', {
            table: 'Country',
            value: currentCustomerPerson.Country
        });
        this.$store.dispatch('updateSelectLinkto', {
            table: 'City',
            value: currentCustomerPerson.City
        });*/
    }

    DisablePerson() {
        console.log(this.data.Code, 'Code del Row to Disable')
        this.$store.dispatch('setCurrentCustomerPerson', this.data.Code);
    }

    ActivatePerson () {
        console.log(this.data.Code, 'Code del Row to Disable')
        this.$store.dispatch('setCurrentCustomerPerson', this.data.Code);
        this.$store.dispatch('ChangeActivationCustomerPerson', this.currentCustomerPerson.Code)
        this.$store.dispatch('showNotificacion',{title: '', message: `Usuario ${this.currentCustomerPerson.WebUser} activado`,type: 'success'});
    }


    getTemplate() {
        return`<div v-if="session.fields.Code != data.Code" :key="'CustomerPersonsActions-'+data.Name" class="custom-actions">
                   <a :key="'actions-showPersonDetail-'+data.Code"  :title="tr('Edit')" class="col-sm-5" data-toggle="modal" data-target="#PersonEditModal" @click="EditPerson()">
                     <i class="fas fa-edit"></i>
                   </a>
                  <a v-if="data.WebUserFlag" :key="'actions-disablePerson-'+data.Code"  :title="tr('Disable Person')" class="col-sm-5" data-toggle="modal" data-target="#ClosePersonModal" @click="DisablePerson()">
                    <i class="fas fa-lock"></i>
                  </a>
                  <a v-else :key="'actions-ActivatePerson-'+data.Code"  :title="tr('Activate Person')" class="col-sm-5"  @click="ActivatePerson()">
                    <i class="fas fa-unlock-alt"></i>
                  </a>
               </div>`;
    }
}

CustomerPersonsActionsComponent.registerComponent();
